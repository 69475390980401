
import { defineComponent } from "vue";
import PhoneCard from "./PhoneCard/index.vue";
export default defineComponent({
  components: { PhoneCard },
  setup() {
    return {
      appName: "益贝多用户端App",
      msglist: [
        "益贝多是一款开心虎旗下的商城软件，包括商品购买、优惠立减活动、赚取积分等功能，目标是让用户可以做到在开心虎旗下的任何产品中都能享受到立减优惠",
      ],
      moduleList: [
        {
          imgurl: require("../../assets/prod-ybd/phon1-index.png"),
          title: "首页",
          msg: "动动手指，新鲜蔬果送到家，让生活简单一下， 新品推荐、限时特惠、每日疯抢、领优惠券解锁更多购物体验。",
          order: true,
        },
        {
          imgurl: require("../../assets/prod-ybd/phon2-gwfl.png"),
          title: "购物分类",
          msg: "对商城商品更好的分类，为用户购买提供更好的体验。",
          order: false,
        },
        {
          imgurl: require("../../assets/prod-ybd/phon3-gwc.png"),
          title: "购物车",
          msg: "商品随时随地加入购物车，一起支付更便捷。",
          order: true,
        },
        {
          imgurl: require("../../assets/prod-ybd/phon4-ddgl.png"),
          title: "订单管理",
          msg: "订单详情一目了然，更多优惠活动给用户更大的利益！",
          order: false,
        },
      ],
    };
  },
});
